import React from 'react'
import Calendar from '../../../components/User/Booking/Calendar/Calendar'
import FormBooking from '../../../components/User/Booking/FormBooking/FormBooking'

const Booking = () => {
  return (
    <div>
        <FormBooking/>
    </div>
  )
}

export default Booking