import React from 'react'
import s from "./style.module.css"
import { useNavigate } from 'react-router-dom'

const ButtonsItaly = () => {
  const navigate = useNavigate();

  const redirectToThankYou = () => {
    window.location.href = "https://testa-webdeveloper.systeme.io/merci-gratuit";
  };
  
  return (
    <div className={s.btnContainer}>
      <script id="form-script-tag-8202647" src="https://testa-webdeveloper.systeme.io/public/remote/page/126102465c40ae010e70129bf42419cec29603b5.js"></script>
        <button className={s.pizzaBtn} onClick={() => navigate("/pizzas")}>Voir les pizzas</button>
        <button className={s.pastaBtn} onClick={() => navigate("/pates")}>Voir des pâtes</button>
        <button className={s.dessertBtn} onClick={() => navigate("/desserts")}>Voir les desserts</button> 
        <button className={s.redirectBtn} onClick={redirectToThankYou}>Aller vers Merci Gratuit</button>
    </div>
  )
}

export default ButtonsItaly